import styled from "@emotion/styled";
import { motion } from "framer-motion";

const me = require('../images/me.png');

export const AboutMe = () => {

    return(
        <WelcomeComponent
            initial={{opacity: 0, y: 200}} 
            animate={{ y: 0, opacity: 1 }}
            transition={{duration: 1}}
        >

            <LeftContainer>
                <h1>Hi there!</h1>
                <p>
                    My name is Peter and I'm 20 year old student in <Important>Poznań University of Technology</Important>.
                    I started 
                    programming 2 years ago at my 4th year in technical school. In this 2 years
                    i have learned multiple programming languages like <Important>javascript  
                     with multiple frameworks</Important> and libraries, <Important>python</Important> with tinker for desktop apps,
                    <Important>lua</Important>, <Important>c</Important> and <Important>c++</Important> but at the end of the day i choose to became 
                    FullStack webdeveloper, so let me build a website for you
              </p>
            </LeftContainer>

            <RightContainer>
                <img src={me} alt="Me" />
            </RightContainer>

        </WelcomeComponent>
    )
}

const WelcomeComponent = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;  
    justify-content: center;
    align-items: center;
    background: rgb(31, 30, 30);

    @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        overflow-y: scroll;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 90%;
        
    }

    ::-webkit-scrollbar-track {
        background: #525151;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #111111;
    }
`;

const LeftContainer = styled.div`
    height: 75%;
    width: 40%;
    color: white;
    display: flex;
    flex-direction: column;

    h1 {
        width: 100%;
        margin-bottom: 16px;
        margin-top: 10%;
        color: #21e521;
    }

    p {
        font-size: 120%;
    }

    @media screen and (max-width: 1000px) {
        min-height: 60%;
        width: 70%;
        text-align: center;
    }

    @media screen and (max-width: 750px) {
        min-height: 90%;
        width: 70%;
        text-align: center;

    }

    @media screen and (max-width: 480px) {
        min-height: 110%;
        width: 80%;
        text-align: center;

    }

    @media screen and (max-width: 400px) {
        min-height: 130%;
        width: 90%;
        text-align: center;

    }
`;

const RightContainer = styled(motion.div)`
    height: 75%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;
    margin-left: 10%;

    img {
        height: 80%;
        border-radius: 50%;
    }

    @media screen and (max-width: 1000px) {
        min-height: 50%;
        width: 70%;
        margin-bottom: 0%;
    }

    @media screen and (max-width: 370px) {

        img {
            height: 60%;
        }
    }

    @media screen and (max-width: 280px) {
        min-height: 40%;
        img {
            height: 40%;
        }
    }
`;

const Important = styled.span`
    color: #0fb4d9;
`;